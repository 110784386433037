<div
        class="modal modal-container d-flex justify-content-center align-items-center bg-brandgradient-diag row"
>
    <div class="col-6">
        <div class="inner bg-white">
            <div class="header bg-darkblue color-white">
                <div class="header-container d-flex align-items-center">
                    <span class="font-header">Viewing Image</span>
                    <button
                            type="button"
                            name="close"
                            (click)="closeModal()"
                            class="btn bg-darkblue btn-close"
                    >
                        <div class="close-line-container">
                            <span class="close-line bg-white"></span>
                            <span class="close-line bg-white"></span>
                        </div>
                    </button>
                </div>
            </div>
            <div style="display:flex;justify-content: center;align-items: center;width:100%">
                <img [src]="imageSrc" style="max-width: 100%;max-height: 75vh;"/>
            </div>
        </div>
    </div>
</div>
