import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '../../auth.service'

export function passConfirmValidator(group: FormGroup): ValidationErrors | null {
    const pass = group.controls['password'] as FormControl <string>
    const conf = group.controls['password_confirmation'] as FormControl <string>

    if (pass.value === conf.value) {
        return null
    }

    return { confirm: true, error: true }
}

@Component({
    selector: 'app-passreset',
    templateUrl: './passreset.component.html',
    styleUrls: ['./passreset.component.scss']
})
export class PassresetComponent implements OnInit {
    resetForm = this.formBuilder.group({
        token: new FormControl('', { validators: Validators.required, nonNullable: true }),
        email: new FormControl('', { validators: [Validators.required, Validators.email], nonNullable: true }),
        password: new FormControl('', { validators: Validators.required, nonNullable: true }),
        password_confirmation: new FormControl('', { validators: [Validators.required, ], nonNullable: true })
    }, { validators: [passConfirmValidator] })
    isSubmitted = false
    isVisible = true
    formSuccess = false
    submitErrors: any

    constructor(
        private authService: AuthService,
        private router: Router,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.isVisible = true
        this.activatedRoute.queryParams.subscribe(params => {
            const token = params['token'] as string
            if (token) {
                this.resetForm.get('token')?.patchValue(token)
            }
        })
    }

    get formControls() { /* eslint-disable-line @typescript-eslint/explicit-function-return-type */
        return this.resetForm.controls
    }

    handleReset(): void {
        if (this.resetForm.invalid) {
            return
        }
        this.isSubmitted = true

        this.authService
            .reset(this.resetForm.getRawValue())
            .then(data => {
                console.log('reset', data)
                this.formSuccess = true
            })
            .catch(err => (this.submitErrors = err))
    }

    updateConfirmValidator(): void {
        Promise.resolve().then(() =>
            this.resetForm.controls['password_confirmation'].updateValueAndValidity())
    }

    showModal(): void {
        this.isVisible = true
    }

    closeModal(): void {
        this.isVisible = false
        this.router.navigate(['/'])
    }
}