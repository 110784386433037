import { Component, OnInit } from '@angular/core'
import { DateTime } from 'luxon'
import { StatusService } from '../../status.service'
import { APIHostEvent } from '@vmk-legacy/common-ts'
import { marked } from 'marked'
import { environment } from '../../../environments/environment'

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
    activeEvents: APIHostEvent[] = []

    constructor(private statusService: StatusService ) {
    }

    ngOnInit(){
        this.statusService.status.subscribe(status => {
            if (status?.events) {
                this.activeEvents = status.events
            } else if (!environment.production) {
                this.activeEvents = [{
                    id: 4,
                    name: 'Party Campaign',
                    shortDescription: 'Party party party party party party party party party party party party party party party party.',
                    start: '2023-11-30T03:20:00.906639Z',
                    end: '2023-12-30T03:20:00.906639Z',
                    isCampaign: true,
                    coverImage: 'https://media.discordapp.net/attachments/1022651263227269163/1173399777099128942/trees_crop.png?ex=6563d0b3&is=65515bb3&hm=288ec2e11d457ab13bff67b2b9785cdb70aa16fc4a0f543dcc0563a130692328&=&width=2182&height=1092'
                }, {
                    id: 2,
                    name: 'Test Event Name 1',
                    shortDescription: 'Short description text Short description text Short description text Short description text',
                    longDescription: 'Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text Long description text ',
                    start: '2023-12-08T12:20:00.906639Z',
                    end: '2023-12-09T03:20:00.906639Z',
                    isCampaign: false,
                    host: 'HOST_Flora',
                    location: 'Location Text',
                    coverImage: 'https://media.discordapp.net/attachments/1022651263227269163/1173399777099128942/trees_crop.png?ex=6563d0b3&is=65515bb3&hm=288ec2e11d457ab13bff67b2b9785cdb70aa16fc4a0f543dcc0563a130692328&=&width=2182&height=1092',
                    parent: {
                        name: 'Party Campaign',
                        id: 4
                    }
                }, {
                    id: 2,
                    name: 'Test Event Name 2',
                    shortDescription: 'Short description text Short description text Short description text Short description text',
                    start: '2023-11-30T03:20:00.906639Z',
                    end: '2023-11-30T03:20:00.906639Z',
                    isCampaign: false,
                    host: 'HOST_Somebody',
                    location: 'Location Text',
                    coverImage: 'https://media.discordapp.net/attachments/1022651263227269163/1173399777099128942/trees_crop.png?ex=6563d0b3&is=65515bb3&hm=288ec2e11d457ab13bff67b2b9785cdb70aa16fc4a0f543dcc0563a130692328&=&width=2182&height=1092',
                    parent: {
                        name: 'Party Campaign',
                        id: 4
                    }
                }, {
                    id: 3,
                    name: 'Test Event Name 3',
                    shortDescription: 'Short description text Short description text Short description text Short description text',
                    start: '2023-12-30T03:20:00.906639Z',
                    end: '2023-12-30T03:25:00.906639Z',
                    isCampaign: false,
                    host: 'HOST_Somebody',
                    location: 'Location Text',
                    coverImage: 'https://media.discordapp.net/attachments/1022651263227269163/1173399777099128942/trees_crop.png?ex=6563d0b3&is=65515bb3&hm=288ec2e11d457ab13bff67b2b9785cdb70aa16fc4a0f543dcc0563a130692328&=&width=2182&height=1092'
                }, {
                    id: 3,
                    name: 'Test Event Name 4',
                    shortDescription: 'Short description text Short description text Short description text Short description text',
                    start: '2024-12-30T03:20:00.906639Z',
                    end: '2024-12-30T03:25:00.906639Z',
                    isCampaign: false,
                    host: 'HOST_Somebody',
                    location: '#some-channel',
                    coverImage: 'https://media.discordapp.net/attachments/1022651263227269163/1173399777099128942/trees_crop.png?ex=6563d0b3&is=65515bb3&hm=288ec2e11d457ab13bff67b2b9785cdb70aa16fc4a0f543dcc0563a130692328&=&width=2182&height=1092'
                }, {
                    id: 3,
                    name: 'Test Event Name 5',
                    shortDescription: 'Short description text Short description text Short description text Short description text',
                    start: '2023-12-09T02:20:00.906639Z',
                    end: '2023-12-30T03:25:00.906639Z',
                    isCampaign: false,
                    host: 'HOST_Somebody',
                    location: 'Location Text',
                    coverImage: 'https://media.discordapp.net/attachments/1022651263227269163/1173399777099128942/trees_crop.png?ex=6563d0b3&is=65515bb3&hm=288ec2e11d457ab13bff67b2b9785cdb70aa16fc4a0f543dcc0563a130692328&=&width=2182&height=1092',
                    parent: {
                        name: 'Party Campaign',
                        id: 4
                    }
                }]
            }
        })
    }

    hasEvents(): boolean {
        return this.activeEvents.length > 0
    }

    upcomingEvents(): APIHostEvent[] {
        return this.activeEvents.sort((a, b) => DateTime.fromISO(a.start).toUnixInteger() - DateTime.fromISO(b.start).toUnixInteger())
    }

    activeCampaign(): APIHostEvent | undefined {
        return this.activeEvents.find(e => e.isCampaign)
    }

    upcomingRelative(event: APIHostEvent): string {
        const start = DateTime.fromISO(event.start)

        return start.toRelative() ?? '?'
    }

    scheduleStatus(event: APIHostEvent): 'past' | 'now' | 'future' | 'cancelled' {
        const start = DateTime.fromISO(event.start)
        const end = DateTime.fromISO(event.end)
        const now = DateTime.now()

        if (end < now) {
            return 'past'
        }

        if (start < now) {
            return 'now'
        }

        return 'future'
    }

    getEventScheduleText(event: APIHostEvent, allowRelativeFormat: boolean = false, ): string {
        const start = DateTime.fromISO(event.start)
        const end = DateTime.fromISO(event.end)

        let startDate = this.formatDateOnly(start, allowRelativeFormat, true)
        let startTime = start.toLocaleString(DateTime.TIME_SIMPLE)

        let endDate = this.formatDateOnly(end, allowRelativeFormat, true)
        let endTime = end.toLocaleString(DateTime.TIME_SIMPLE)

        if (startDate === endDate) {
            endDate = ''
        }

        if (!endDate && startTime === endTime) {
            endTime = ''
        }

        const timeZone = end.toFormat('ZZZZ')

        if (!endDate && !endTime) {
            return `${startDate} ${startTime} ${timeZone}`
        }

        return `${startDate} ${startTime} - ${endDate} ${endTime} ${timeZone}`
    }

    formatDateOnly(date: DateTime, allowRelative: boolean, omitFutureSameYear: boolean): string {
        const now = DateTime.now()

        if (allowRelative) {
            const isToday = date.startOf('day').toUnixInteger() === now.startOf('day').toUnixInteger()
            const isTomorrow = date.startOf('day').toUnixInteger() === now.startOf('day').plus({ days: 1 }).toUnixInteger()

            if (isToday) {
                return 'Today'
            }

            if (isTomorrow) {
                return 'Tomorrow'
            }
        }

        if (omitFutureSameYear && date > now && date.hasSame(now, 'year')) {
            return date.toFormat('EEE, MMM d')
        }

        return date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
    }

    protected readonly marked = marked
    protected readonly encodeURI = encodeURI
}