<footer class="text-white" style="opacity: 0.5">
    <div class="row align-items-center pb-3">
        <div class="mx-5 d-flex flex-column footer-content">
            <h2></h2>
            <p>
                VMK Legacy ("VMKL") is a 100% FREE passion project to recreate
                the beloved Virtual Magic Kingdom game. VMKL is funded out-of-pocket by a group of fans. We refuse to
                solicit or accept payments, donations, or advertising revenue of any kind.
            </p>
            <p>
                VMKL is not affiliated with or endorsed by The Walt Disney Company ("Disney"). By playing VMKL,
                you hereby release Disney and affiliates from any and all liability caused to you or others by the use of VMKL.
            </p>
            <p>
                ⊙ 2018 - 2024 VMK Legacy/NETCOT Team
            </p>
        </div>
    </div>
</footer>
