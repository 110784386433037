import { Component, OnInit, Pipe, PipeTransform } from '@angular/core'
import { APIKBPage, VMKLegacyAPI } from '@vmk-legacy/common-ts'
import { ActivatedRoute } from '@angular/router'
import { marked, RendererThis, TokenizerThis, Tokens } from 'marked'
import { DomSanitizer, Meta, Title } from '@angular/platform-browser'
import { Location } from '@angular/common'
import { DateTime, Duration } from 'luxon'
import { environment } from '../../../environments/environment'

@Component({
    selector: 'app-kb-page',
    templateUrl: './kb-page.component.html',
    styleUrls: ['./kb-page.component.scss']
})
export class KbPageComponent implements OnInit {
    page?: APIKBPage
    pageBody?: string
    loadError?: string
    backPath?: string
    backParams?: object
    normalizedReleases: { type: string, description: string, start: string, end?: string }[] = []

    constructor(
        private vmklAPI: VMKLegacyAPI,
        private route: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta,
        public location: Location,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        this.metaService.removeTag('name=description')

        this.route.queryParams.subscribe(params => {
            if ('back' in params && params['back']) {
                const url = new URL(location.origin + params['back'])
                this.backPath = url.pathname
                // @ts-ignore
                this.backParams = Object.fromEntries(url.searchParams)
            } else {
                this.backPath = undefined
                this.backParams = undefined
            }
        })
        this.route.params.subscribe(routeParams => {
            this.loadError = undefined

            if ('slug' in routeParams) {
                const pageSlug = String(routeParams['slug'])
                if (!pageSlug) {
                    this.loadError = 'Page not found, please check the URL.'
                    return
                }
                this.vmklAPI.getKBPage(pageSlug).then((page) => {
                    this.metaService.removeTag('name=description')

                    if (page) {
                        this.titleService.setTitle(page.title + ' | VMK Legacy')
                        this.page = page
                        // @ts-ignore
                        this.pageBody = page.body ? this.sanitizer.bypassSecurityTrustHtml(marked(page.body) as string) : undefined
                        this.loadError = undefined
                    }
                }).catch(() => {
                    this.loadError = 'Page not found, please check the URL.'
                })
            } else if ('itemUid' in routeParams) {
                const itemUid = String(routeParams['itemUid'])
                if (!itemUid) {
                    this.loadError = 'Page not found, please check the URL.'
                    return
                }
                this.vmklAPI.getKBItemPage(itemUid).then((page) => {
                    this.metaService.removeTag('name=description')

                    if (page) {
                        this.titleService.setTitle(page.title + ' | VMK Legacy')
                        this.page = page
                        // @ts-ignore
                        this.pageBody = page.body ? (marked(page.body) as string) : undefined
                        this.loadError = undefined
                        this.normalizeReleases()
                    }
                }).catch(() => {
                    this.loadError = 'Page not found, please check the URL.'
                })
            }
        })
    }

    normalizeReleases(): void {
        if (!this.page?.subjectItem?.releases) {
            this.normalizedReleases = []
            return
        }

        const normalize = (date: DateTime): DateTime => {
            if (date && date.hour < 8) {
                return date.minus(Duration.fromObject({ hours: 9 }))
            } else {
                return date
            }
        }

        const sorted = this.page.subjectItem.releases.sort((a, b) => a.start > b.start ? -1 : 1)
        const releases = []
        const removedDupes = []

        let lastRelease
        for (const release of sorted) {
            const start = DateTime.fromISO(release.start)
            const end = release.end ? normalize(DateTime.fromISO(release.end)) : start

            if (lastRelease) {
                const lastStart = DateTime.fromISO(lastRelease.start)
                const lastEnd = lastRelease.end ? normalize(DateTime.fromISO(lastRelease.end)) : lastStart

                if (lastRelease.type === release.type && lastRelease.description === release.description) {
                    if (lastStart.hasSame(end, 'day') || lastStart.hasSame(end.plus(Duration.fromObject({ day: 1 })), 'day')) {
                        lastRelease.start = release.start
                        removedDupes.push(release)
                        continue
                    }
                }
                releases.push(lastRelease)
            }

            lastRelease = release
        }
        if (lastRelease) {
            releases.push(lastRelease)
        }

        this.normalizedReleases = releases
    }

    getTimeString(release: { type: string, description: string, start: string, end?: string }) {
        const startDate = DateTime.fromISO(release.start)
        let endDate = release.end ? DateTime.fromISO(release.end) : null

        if (endDate && endDate.hour < 8) {
            endDate = endDate.minus(Duration.fromObject({ hours: 9 }))
        }

        if (endDate && endDate < startDate) {
            endDate = null
        }

        const startStr = startDate.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
        const endStr = endDate?.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)

        let string = startStr
        if (endStr) {
            if (startStr !== endStr) {
                string += ' - ' + endStr
            }
        } else if (release.type === 'shop' || release.type === 'drop') {
            string += ' - Present'
        }

        return string
    }

    protected readonly environment = environment
}

const StartVideoTagRegex = /^::video/
const VideoTagRegex = /^::video\[([^,]*)(,([0-9]*))?(,([0-9]*))?\]$/
const VideoIDRegex = /(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[-a-zA-Z0-9_]{11,}(?!\S))\/)|(?:\S*v=|v\/)))([-a-zA-Z0-9_]{11,})/

const videoExtension = {
    name: 'videoExt',
    level: 'inline',
    start: (src: string) => {
        const m = src.match(StartVideoTagRegex) as RegExpMatchArray

        if (m) {
            return m.index
        }
        return undefined
    },
    tokenizer: function (this: TokenizerThis, src: string, tokens: Tokens.Generic[]) {
        const match = src.match(VideoTagRegex)

        if (match) {
            return {
                type: 'videoExt',
                raw: match[0],
                text: match[1].trim(),
                tokens: [],
                width: match[3] ?? 560,
                height: match[5] ?? 315
            }
        }
        return undefined
    },
    renderer: function (this: RendererThis, token: Tokens.Generic) {
        // @ts-ignore (until typings are up to date)
        const [_, id] = VideoIDRegex.exec(token.text)

        let url = `https://www.youtube.com/embed/${id}`
        return `
      <iframe width="${token['width']}" height="${token['height']}" src="${url}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`
    }
}

const StartFlashRegex = /^::flash/
const FlashTagRegex = /^::flash\[([^,]*)(,([0-9]*))?(,([0-9]*))?\]$/

const flashExtension = {
    name: 'flashExt',
    level: 'inline',
    start: (src: string) => {
        const m = src.match(StartFlashRegex) as RegExpMatchArray
        if (m) {
            return m.index
        }
        return undefined
    },
    tokenizer: function (this: TokenizerThis, src: string, tokens: Tokens.Generic[]) {
        const match = src.match(FlashTagRegex)

        if (match) {
            return {
                type: 'flashExt',
                raw: match[0],
                text: match[1].trim(),
                tokens: [],
                width: match[3] ?? 560,
                height: match[5] ?? 315
            }
        }
        return undefined
    },
    renderer: function (this: RendererThis, token: Tokens.Generic) {
        return `
      <object><embed width="${token['width']}" height="${token['height']}" src="${token['text']}"></embed></object>`
    }
}
marked.use({ extensions: [videoExtension, flashExtension] })

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {
    }

    transform(value: string) {
        return this.sanitized.bypassSecurityTrustHtml(value)
    }
}