import { Component } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthService } from '../../auth.service'

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent {
    recoverForm = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]]
    })
    isSubmitted = false
    isVisible = true
    isEnabled = true
    submitErrors: any
    recoverSent = false

    constructor(
        private authService: AuthService,
        private router: Router,
        private formBuilder: FormBuilder
    ) {}

    get formControls() { /* eslint-disable-line @typescript-eslint/explicit-function-return-type */
        return this.recoverForm.controls
    }

    handleRecover(): void {
        console.log('recover')
        if (this.recoverForm.invalid) {
            return
        }
        const email = this.recoverForm.controls.email.value
        if (!email) {
            return
        }
        this.isSubmitted = true
        this.authService
            .recover(email)
            .then(data => {
                console.log('recover data', data)
                console.log('recover success')
                this.recoverSent = true
            })
            .catch(err => {
                console.log('error found', err)
                this.submitErrors = err
            })
    }

    resolved(captchaResponse: string): void {
        console.log(`Resolved response token: ${captchaResponse}`)
    }

    showModal(): void {
        this.isVisible = true
    }

    closeModal(): void {
        this.isVisible = false
        this.router.navigate(['/'])
    }
}