<div class="login inner bg-darkblue"
     style="color:#fff;margin-top:20px;position:relative;overflow:initial;text-align:center" id="login">
    <img src="assets/img/players/jakesit.png" style="transform: scaleX(-1);
    position: absolute;
    top: -97px;
    right: 10px;">
    <h2 style="margin-top:0">Log In</h2>
    <form
            [formGroup]="authForm"
            class="login-form form-container"
            (ngSubmit)="login()"
            *ngIf="!user?.user"
            style="padding: 0"
    >
        <div [ngClass]="{ 'has-error': submitErrors }" class="form-group">
            <div *ngIf="submitErrors">
                <em>{{ submitErrors }}</em>
            </div>
        </div>
        <div
                [ngClass]="{
            'has-error':
              (isSubmitted && formControls.email.errors) || submitErrors
          }"
                class="form-group"
        >
            <input
                    type="email"
                    placeholder="Email"
                    formControlName="email"
                    id="email-field"
                    email
                    class="bg-white"
            />
            <div
                    *ngIf="isSubmitted && formControls.email.errors"
                    class="help-block"
            >
                <div *ngIf="formControls.email.errors['required']">
                    <em>Email is required</em>
                </div>
                <div *ngIf="formControls.email.errors['email']">
                    <em>Please enter a valid email</em>
                </div>
            </div>
        </div>
        <div
                [ngClass]="{
            'has-error':
              (isSubmitted && formControls.password.errors) || submitErrors
          }"
                class="form-group"
        >
            <input
                    type="password"
                    placeholder="Password"
                    formControlName="password"
                    id="pass-field"
                    class="bg-white"
            />
            <div
                    *ngIf="isSubmitted && formControls.password.errors"
                    class="help-block"
            >
                <div *ngIf="formControls.password.errors['required']">
                    <em>Password is required</em>
                </div>
            </div>
        </div>
        <div class="form-group"
        >
            <label><input
                    type="checkbox"
                    id="remember"
                    formControlName="remember"
                    checked
            />
                Remember me</label>
        </div>

        <button type="submit" class="btn btn-green w-100">Log in</button>
        <a class="forgot-password" routerLink="/auth/recover" style="color:#fff">
            <small>Forgot Password?</small>
        </a>
        <a routerLink="/auth/register" class="d-block btn btn-register w-100 text-center">Sign Up for Free</a>
    </form>
</div>