// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CookieOptions } from 'ngx-cookie-service'

export const environment = {
    production: false,
    CLIENT_URL: 'https://client.progress.sandbox.vmk.one', API_URL: 'https://api.progress.sandbox.vmk.one', RENDER_URL: 'https://render.progress.sandbox.vmk.one',
    ASSETS_URL: 'https://assets.progress.sandbox.vmk.one',
    THUMBS_URL: 'https://thumbs.progress.sandbox.vmk.one',
    CookieConfig: {
        domain: '.progress.sandbox.vmk.one',
        path: '/',
        expires: 7200,
        secure: true,
        sameSite: 'Lax'
    } as CookieOptions,
    POSTHOG_KEY: 'phc_yNiIp9KaJSHzyM0Zo3edvHOQc0u6uwYF1DDfEf68L2i',
    POSTHOG_HOST: 'https://esmeralda.progress.sandbox.vmk.one'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
