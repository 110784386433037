<h1>Knowledge Base Item Search</h1>
<p>
    Search the items available on VMK Legacy and learn more about them! Contains up-to-date release data for items released in Shops, Sword in the Stone, NPCs, and Redemption Codes. We are working to fill out remaining event and special release information. <a routerLink="/kb/shops">View the current shop catalog</a>.
</p>
<form (submit)="navigateToSearch()" style="max-width: 500px;margin:0 auto;margin-bottom:25px">
    <div style="display: flex;gap: 10px;align-items: baseline;">
        <input type="search" class="bg-white" placeholder="Search" [(ngModel)]="query" name="kb-search" minlength="3"
               maxlength="25" required/>
        <button class="btn btn-green">Go</button>
    </div>
    <div style="display:flex;gap: 10px;align-items: baseline;justify-content: space-evenly;text-align:center">
        <label>Filter: <select [(ngModel)]="type" name="type" (ngModelChange)="navigateToSearch()">
            <option value="">All</option>
            <option value="pins">Pins</option>
            <option value="clothes">Clothing</option>
            <option value="furni">Furniture</option>
            <option value="posters">Posters</option>
        </select></label>
        <div style="display:inline-flex">
            <label>Sort:
                <select [(ngModel)]="sort" name="sort"
                        (ngModelChange)="navigateToSearch()">
                    <option value="name-asc">Name: A-Z</option>
                    <option value="name-desc">Name: Z-A</option>
                    <option value="release-asc">Release: Old-New</option>
                    <option value="release-desc">Release: New-Old</option>
                </select>
            </label>
        </div>
    </div>
</form>
<div class="kb-index-table">
    <div *ngIf="results && results.total > 0">
        <div class="kb-resultcount">
            {{ results.total + ' Result' + (results.total === 1 ? '' : 's') }} {{ lastQuery ? 'for "' + lastQuery + '" ' : '' }}—
            <a (click)="gotoPage(results.currentPage - 1)" *ngIf="results.currentPage !== 1">«</a>
            Page {{ results.currentPage }} of {{ results.lastPage }}
            <a (click)="gotoPage(results.currentPage + 1)" *ngIf="results.currentPage !== results.lastPage">»</a>
        </div>
        <div class="kb-index-row">
            <div></div>
            <div><b>Name</b></div>
            <div><b>Type</b></div>
            <div><b>Released</b></div>
        </div>
        <div class="kb-index-row" *ngFor="let result of results.items">
            <div class="kb-index-img"><a [routerLink]="'/kb/items/' + result.uid" [queryParams]="currentQueryParams()"><img
                    [src]="environment.THUMBS_URL + '/item_' + result.uid + '-sm.png'" draggable="false" loading="lazy"/></a></div>
            <div><a [routerLink]="'/kb/items/' + result.uid" [queryParams]="currentQueryParams()">{{ result.name }}</a></div>
            <div>
                <span *ngIf="result.type === EItemType.Pin">Pin</span>
                <span *ngIf="result.type === EItemType.Clothing">Clothing</span>
                <span *ngIf="result.type === EItemType.Furniture">Furniture</span>
                <span *ngIf="result.type === EItemType.Room">Room</span>
                <span *ngIf="result.type === EItemType.Pack">Pack</span>
                <span *ngIf="result.type === EItemType.Poster">Poster</span>
            </div>
            <div>
                {{ DateTime.fromISO(result.release).toLocaleString(DateTime.DATE_SHORT) }}
            </div>
        </div>
        <div class="kb-paginator">
            <button *ngFor="let item of [].constructor(numPages()); let i = index" (click)="gotoPage(i + 1)"
                    [disabled]="i + 1 === results.currentPage"
            >{{ i + 1 }}
            </button>
        </div>
    </div>
    <div *ngIf="results?.total === 0 || error" class="kb-noresults">
        {{ error ?? 'No results found for "' + lastQuery + '"' }}
    </div>
</div>
