import { Component, OnInit } from '@angular/core'
import { APIShop, EItemType, VMKLegacyAPI } from '@vmk-legacy/common-ts'
import { Meta } from '@angular/platform-browser'

@Component({
    selector: 'app-kb',
    templateUrl: './kb.component.html',
    styleUrls: ['./kb.component.scss']
})
export class KbComponent implements OnInit {

    shops?: APIShop[]
    currentShop: number = 0
    EItemType = EItemType

    updateTime: Date = new Date()

    constructor(private vmklAPI: VMKLegacyAPI,
        private metaService: Meta) {

    }

    ngOnInit(): void {
        this.updateTime = new Date()
        this.vmklAPI.getShopReleases().then(data => {
            const shops = data.shops
            shops.sort((a, b) => {
                const aName = a.name.replace('"', '').toLowerCase()
                const bName = b.name.replace('"', '').toLowerCase()
                if (aName < bName) {
                    return -1
                }
                if (aName > bName) {
                    return 1
                }
                return 0
            }).sort((a, b) => {
                if (a.require_unlock < b.require_unlock) {
                    return -1
                }
                if (a.require_unlock > b.require_unlock) {
                    return 1
                }
                return 0
            }).forEach(shop => {
                if (shop.name === 'VMK Central') {
                    shop.name = 'Rooms'
                }
            })
            this.shops = shops.filter(s => s.catalog_items.length !== 0)
        })

        this.metaService.updateTag({
            name: 'description',
            content: 'Check out the latest catalog of items at the VMK Legacy shops.'
        })
    }

}
