<div
        class="modal modal-container d-flex justify-content-center align-items-center bg-brandgradient-diag row"
        *ngIf="isVisible"
>
    <div class="col-6">
        <div class="inner bg-white">
            <div class="header bg-darkblue color-white">
                <div class="header-container d-flex align-items-center">
                    <span class="font-header">Create a Free Account</span>
                    <button
                            type="button"
                            name="close"
                            (click)="closeModal()"
                            class="btn bg-darkblue btn-close"
                    >
                        <div class="close-line-container">
                            <span class="close-line bg-white"></span>
                            <span class="close-line bg-white"></span>
                        </div>
                    </button>
                </div>
            </div>
            <form
                    [formGroup]="registerForm"
                    class="register-form form-container"
                    (ngSubmit)="handleRegister()"
                    *ngIf="!registerSuccess"
            >
                <p>
                    Sign up to begin reliving the Legacy of VMK!
                </p>
                <div
                        [ngClass]="{ 'has-error': submitErrors }"
                        *ngIf="submitErrors"
                        class="form-group"
                >
                    <div>
                        <em>{{ submitErrors }}</em>
                    </div>
                </div>
                <div
                        [ngClass]="{
            'has-error':
              (isSubmitted && formControls['email'].errors) || submitErrors
          }"
                        class="form-group"
                >
                    <label for="email-field">Email</label>
                    <input
                            type="email"
                            placeholder="Email"
                            formControlName="email"
                            id="email-field"
                            autocomplete="email"
                            maxlength="255"
                            email
                            required
                    />
                    <div
                            *ngIf="isSubmitted && formControls['email'].errors"
                            class="help-block"
                    >
                        <div *ngIf="formControls['email'].errors['required']">
                            <em>Email is required</em>
                        </div>
                        <div *ngIf="formControls['email'].errors['email']">
                            <em>Enter a valid email</em>
                        </div>
                    </div>
                </div>
                <div
                        [ngClass]="{
            'has-error':
              (isSubmitted && formControls['password'].errors) || submitErrors
          }"
                        class="form-group"
                >
                    <label for="password-field">Password</label>
                    <input
                            type="password"
                            id="password-field"
                            placeholder="Password"
                            formControlName="password"
                            autocomplete="new-password"
                            maxlength="60"
                            (ngModelChange)="updateConfirmValidator()"
                    />
                    <div
                            *ngIf="isSubmitted && formControls['password'].errors"
                            class="help-block"
                    >
                        <div *ngIf="formControls['password'].errors['required']">
                            <em>Password is required</em>
                        </div>
                    </div>
                </div>
                <div
                        [ngClass]="{
            'has-error':
              (isSubmitted && formControls['password_confirmation'].errors) ||
              submitErrors
          }"
                        class="form-group"
                >
                    <label for="pass-confirm">Confirm Password</label>
                    <input
                            type="password"
                            id="pass-confirm"
                            autocomplete="new-password"
                            formControlName="password_confirmation"
                            required
                            maxlength="60"
                    />
                    <div *ngIf="isSubmitted && registerForm.hasError('confirm')"
                         class="help-block"
                    >
                        <em>Passwords do not match!</em>
                    </div>
                    <div
                            *ngIf="isSubmitted && formControls['password_confirmation'].errors && formControls['password_confirmation'].errors['required']"
                            class="help-block"
                    >
                        <em>Please confirm your password</em>
                    </div>
                </div>
                <div
                        [ngClass]="{
            'has-error':
              (isSubmitted && formControls['agree_terms'].errors) || submitErrors
          }"
                        class="form-group"
                >
                    <label for="agree_terms" class="d-flex align-items-center">
                        <input
                                type="checkbox"
                                placeholder="Password"
                                formControlName="agree_terms"
                                id="agree_terms"
                        />
                        <span class="text-transform-none"
                        >I agree to the
              <a href="/terms" target="_blank">Terms of Service</a> &
              <a href="/rules" target="_blank">Game Rules</a></span
                        ></label
                    >
                    <div
                            *ngIf="isSubmitted && formControls['agree_terms'].errors"
                            class="help-block"
                    >
                        <div *ngIf="formControls['agree_terms'].errors['required']">
                            <em>Please agree to the terms of service and game rules.</em>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-green w-100">Register</button>
            </form>
            <div *ngIf="registerSuccess" class="w-100 px-4 py-2">
                <p class="text-center">
                    Your account has been created! Verify your email by clicking the link we sent you.
                </p>
                <button
                        type="button"
                        name="close"
                        (click)="closeModal()"
                        class="btn-gray btn register btn-register"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
