<section class="py-4 inner-page">
    <div *ngIf="!profile">
        Loading...
    </div>
    <div class="row pt-4 justify-content-center" *ngIf="profile">
        <div class="col-6 inner-page-content">
            <h2 style="margin-top: 1cm">{{ profile.ign }}</h2>
            <div class="row">
                <div class="col-6">
                    <img *ngIf="profile.avatarUrl" src="{{ profile.avatarUrl }}&anim=sit&dir=3"/>
                    <div *ngIf="profile.signature">{{ profile.signature }}</div>
                    <div *ngIf="profile.pronouns">{{ profile.pronouns }}</div>
                    <div *ngIf="profile.joined">Playing since {{ profile.joined | date:"MMMM dd, yyyy":'America/New_York' }}</div>
                    <div *ngIf="profile.lastOnline">Last seen {{ profile.lastOnline | date:"MMMM dd, yyyy":'America/New_York' }}</div>
                    <div *ngIf="profile.badges">
                        <img *ngFor="let badge of profile.badges" [src]="'/assets/img/badges/badge_' + badge + '.png'"/>
                    </div>
                </div>
                <div class="col-6">
                    <div *ngIf="profile.lanyard">
                        <h3>Pin Lanyard</h3>
                        <div id="pin-contain">
                            <div class="profile-pin" *ngFor="let pin of profile.lanyard"
                                 [title]="pin.name ?? ''">
                                <img [src]="environment.THUMBS_URL + '/item_' + pin.defUid + '-sm.png'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
