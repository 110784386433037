import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Location } from '@angular/common'

@Component({
    selector: 'app-lightbox',
    templateUrl: './lightbox.component.html',
    styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnInit {
    imageSrc = ''
    private backUrl = ''

    constructor(
        private location: Location,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            if (params['url']) {
                this.imageSrc = params['url']
            }
        })
    }

    closeModal(): void {
        // Lightbox component route is supported at /lightbox or /events/lightbox. Try to figure out the path level above.
        const upLevel = this.activatedRoute.snapshot.root.firstChild?.url[0]?.path ?? '/'

        this.router.navigate([upLevel])
    }
}
