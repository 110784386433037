<div class="inner-page-content">
    <h2>VMK Legacy Terms of Service</h2>
    <p>
        VMK Legacy resides at vmklegacy.com, and formerly netcot.center. All other
        domain names are not property of VMK Legacy and should not be trusted. We
        don’t take responsibility if you created or used accounts on other
        domains than vmklegacy.com or netcot.center.
    </p>
    <p>
        VMK Legacy is a zero profit, fan recreation of the Virtual Magic
        Kingdom (VMK) that is in no way affiliated with the Walt Disney Company
        or Sulake Corporation.
    </p>
    <p>
        VMK Legacy does not request anyone to pay for special in-game
        features when certain events occur in the game. All players have access
        to all features unless their account is suspended or banned.
    </p>
    <p>By registering to play, you agree that:</p>
    <ul>
        <li>
            you are at least 13 years of age if you reside in the United States,
            or
        </li>
        <li>
            you are at least 16 years of age if you reside in the European Union
            or one of its member states or
        </li>
        <li>
            you are able to legally bind yourself to these terms as per local
            jurisdiction
        </li>
    </ul>
    <p>We reserve the right to change this legal disclaimer at any time.</p>
    <p>
        For any abuse complaints please email us at
        <a href="mailto:contact@vmklegacy.com"><b>contact&#64;vmklegacy.com</b></a
        >.
    </p>
    <p>
        VMK Legacy works hard to create a safe environment for all its players. By
        playing the game, you agree to follow any rules and regulations
        regarding internet use in your country, and that you have read and
        agreed to all VMK Legacy Policies and <a routerLink="/rules"><b>Rules</b></a>.
    </p>
    <p><small>Last updated on 07/31/22 for name changes</small></p>
</div>
