<app-nav></app-nav>
<app-screenshot-slider *ngIf="isHome()"></app-screenshot-slider>
<div *ngIf="!isHome()" style="margin-top: min(20vw, max(50px, 7vh))"></div>
<div id="main-contain">
    <div id="middle">
        <div style="padding-top:20px">
            <div>
                <div class="bg-darkpurple" id="main-content">
                    <router-outlet (activate)="onActivate()" style="position:absolute"></router-outlet>
                </div>
            </div>
            <app-footer></app-footer>
        </div>
    </div>

    <div id="left-bar" class="desktop-only">
        <div>
            <app-sidebar></app-sidebar>
        </div>
    </div>
</div>
