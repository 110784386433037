<div class="inner bg-purple" style="display:block;height:initial" *ngIf="!bulletinPosts?.length && latestNews">
    <a [routerLink]="['/news', latestNews.id]" style="z-index: 10;position: relative;">
        <img *ngIf="latestNews.feature_image" [src]="latestNews.feature_image"
             style="float:left;max-width:150px;max-height: 125px;border-radius: 7px;margin-right:20px;"/>
    </a>
    <h2 style="margin-top: 0;display:inline" class="no-line">News: <a
            [routerLink]="['/news', latestNews.id]">{{latestNews.title}}</a></h2>
    <small class="small-highlight">{{latestNews.posted_at | date:"MMMM dd, yyyy":'America/New_York'}}</small>
    <p *ngIf="latestNews.preview">{{ latestNews.preview }} <a [routerLink]="['/news', latestNews.id]">continue
        reading</a></p>
    <a [routerLink]="['/news', latestNews.id]" class="btn bg-purple" *ngIf="!latestNews.preview">Read
        Newsletter</a>
</div>

<div *ngIf="bulletinPosts?.length">
    <div class="bulletin">
        <div class="inner bg-purple bulletin-post" *ngFor="let post of bulletinPosts">
            <div>
                <div style="margin:-1.25rem;margin-bottom:0">
                    <div *ngFor="let image of post.images.slice(0,1)" style="height: 125px;background-size:cover;cursor:pointer;background-position:center" [style]="{ backgroundImage: 'url(' + image + ')' }"
                    [routerLink]="['lightbox', encodeURI(image)]"></div>
                </div>
                <div [innerHTML]="marked(post.message)"></div>
                <div style="flex-grow:1"></div>
                <small class="small-highlight">{{post.time | date:"MMMM dd, yyyy":'America/New_York'}}</small>
            </div>
        </div>
    </div>
</div>