<div>
    <div *ngIf="article">
        <article>
            <div class="article-header">
                <img [src]="article.feature_image" class="feature-img" *ngIf="article.feature_image"/>
                <h1>{{article.title}}</h1>
                <small *ngIf="article.preview" class="preview">{{article.preview}}</small>
                <a [href]="'/news/' + article.id"><small class="small-highlight">VMKL News — {{article.posted_at | date:"MMMM d, yyyy":'America/New_York'}}</small></a>
            </div>

            <div *ngIf="!articleIsLatest" class="inner bg-purple archive-alert">
                <h3>Archived Newsletter</h3>
                <a class="btn bg-pink" [routerLink]="['/news']">Go to Latest News</a>
            </div>
            <div [innerHTML]="article.body | safeHtml" class="article-body"></div>

        </article>
    </div>
    <div *ngIf="!article" class="article-status">
        <b>{{articleStatus}}</b>
    </div>
</div>
<aside *ngIf="otherArticles?.length">
    <hr/>
    <p *ngIf="!articleIsLatest">
        You're viewing an older article, check out <a [routerLink]="['/news']">the latest newsletter</a>!
    </p>
    <h3>Older News</h3>
    <div style="display: flex;flex-wrap: wrap;gap: 25px">
        <ng-container *ngFor="let art of otherArticles">
            <div *ngIf="article && art.id !== article.id" style="flex-basis: max(250px, 31%)">
                <a [routerLink]="['/news', art.id]" style="z-index: 10;position: relative;" *ngIf="art.feature_image">
                    <img [src]="sanitizeImageUrl(art.feature_image)"
                         onerror="this.style.display='none'"
                         style="float:left;max-width:75px;max-height: 75px;border-radius: 7px;margin-right:20px;"/>
                </a>
                <a [routerLink]="['/news', art.id]" style="max-width: 300px">{{art.title}}</a>
                <small class="small-highlight">{{art.posted_at | date:"MMMM d, yyyy":'America/New_York'}}</small>
            </div>
        </ng-container>
    </div>
</aside>