import { Component, OnInit } from '@angular/core'
import { APIArticleFull, APIBulletinPost } from '@vmk-legacy/common-ts'
import { NewsService } from '../news.service'
import { StatusService } from '../status.service'
import { marked } from 'marked'

@Component({
    selector: 'app-latest-news',
    templateUrl: './latest-news.component.html',
    styleUrls: ['./latest-news.component.scss']
})
export class LatestNewsComponent implements OnInit {
    latestNews?: APIArticleFull
    bulletinPosts?: APIBulletinPost[]

    constructor(
        private newsService: NewsService,
        private statusService: StatusService,
    ) {
    }

    ngOnInit(): void {
        this.newsService
            .latestArticle
            .subscribe(article => (this.latestNews = article))


        this.statusService.status.subscribe(status => {
            this.bulletinPosts = status?.bulletin
        })
    }

    protected readonly marked = marked
    protected readonly encodeURI = encodeURI
}
