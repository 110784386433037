import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { APIStatus, VMKLegacyAPI } from '@vmk-legacy/common-ts'

@Injectable({
    providedIn: 'root'
})
export class StatusService {

    statusSource = new BehaviorSubject<APIStatus | undefined>(undefined)
    status = this.statusSource.asObservable()

    constructor(private vmklAPI: VMKLegacyAPI) {
        this.getStatus()
        setInterval(() => this.getStatus(), 100000)
    }

    hasRulesArticle(): boolean {
        const navPages = this.statusSource.getValue()?.homeSlots?.['1'] ?? []

        return navPages.some(p => p.slug === 'rules')
    }

    getStatus(): Promise<APIStatus | undefined> {
        if (location.href.includes('localhost')) {
            return new Promise<APIStatus>(resolve => {
                const data: APIStatus = {
                    schedule: {
                        opDate: '2020-01-01',
                        hours: {
                            open: '09:00',
                            close: '01:00'
                        }
                    },
                    bulletin: [{
                        id: 0,
                        time: '2023-11-30T00:30:00.000000Z',
                        message: 'test',
                        images: []
                    }]
                    /*homeSlots: {
                        1: [
                            { slug: 'casting', title: 'Casting' }
                        ]
                    }*/
                }
                this.statusSource.next(data)

                resolve(data)
            })
        }
        return this.vmklAPI.getStatus().then(data => {
            this.statusSource.next(data)
            return data
        })
            .catch(err => {
                console.log('status error', err)
                return undefined
            })
    }
}
