import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { BehaviorSubject } from 'rxjs'
import {
    APILoginResponse,
    APIPlayerBySessionResponse,
    APIRegisterResponse,
    IPlayerBasic,
    VMKLegacyAPI
} from '@vmk-legacy/common-ts'
import posthog from 'posthog-js'

@Injectable({ providedIn: 'root' })
export class AuthService {
    private userSource = new BehaviorSubject<APIPlayerBySessionResponse | undefined>(undefined)
    currentUser = this.userSource.asObservable()

    constructor(
        private cookieService: CookieService,
        private vmklAPI: VMKLegacyAPI
    ) {
        this.getPlayerBySession()
    }

    identifyUserToAnalytics(user: IPlayerBasic): void {
        if (user.id) {
            posthog.identify(`player:${String(user.id)}`, {
                ign: user.ign,
                pid: user.id
            })
        }
    }

    login(creds: { email: string, password: string, remember?: boolean | null }): Promise<APILoginResponse> {
        return this.vmklAPI
            .login(creds.email, creds.password, true, creds.remember ?? false)
            .then(data => {
                this.identifyUserToAnalytics(data.user)
                posthog.capture('loggedin')
                this.userSource.next(data)
                return data
            })
            .catch(err => {
                this.userSource.next(undefined)
                console.log(err)
                throw err
            })
    }

    register(body: {
        email: string;
        password: string;
        password_confirmation: string;
        agree_terms: boolean;
        'g-recaptcha-response': string;
    }): Promise<APIRegisterResponse> {
        return this.vmklAPI
            .register(body)
            .then(res => {
                posthog.capture('registered')
                console.log(res)
                return res
            })
    }

    activateAccount(code: string): Promise<APILoginResponse> {
        return this.vmklAPI
            .activate(code)
            .then((data) => {
                this.identifyUserToAnalytics(data.user)
                posthog.capture('activated')
                this.userSource.next(data)
                return data
            })
            .catch((err: any) => {
                this.userSource.next(undefined)
                console.log(err)
                throw err
            })
    }

    logout(): Promise<void> {
        return this.vmklAPI.logout().then(res => {
            posthog.capture('logout')
            posthog.reset()
            this.userSource.next(undefined)
            return res
        })
    }

    recover(email: string): Promise<void> {
        return this.vmklAPI
            .requestResetEmail(email)
            .then(res => {
                posthog.capture('request-recover')
                return res
            })
    }

    reset(body: {
        token: string;
        email: string;
        password: string;
        password_confirmation: string;
    }): Promise<void> {
        return this.vmklAPI
            .performPassReset(body)
            .then(res => {
                posthog.capture('reset-pass')
                console.log('reset', res)
                return res
            })
            .catch(err => {
                console.log('reset error', err)
                throw err
            })
    }

    getPlayerBySession(): Promise<APIPlayerBySessionResponse> {
        return this.vmklAPI
            .getPlayerBySession(true)
            .then(data => {
                this.identifyUserToAnalytics(data.user)
                this.userSource.next(data)
                return data
            })
            .catch(err => {
                this.userSource.next(undefined)
                throw err
            })
    }
}