<section
        class="hero position-relative d-flex justify-content-center align-items-center"
        [ngClass]="{
    'welcome-hero-container': pageTitle === 'welcome'
  }"
>
    <div class="w-100 h-100 welcome-hero hero-text"
         style="filter: drop-shadow(0 2px 5px rgba(0, 0, 0, .25));max-width: 1300px;margin: 0 auto;justify-content: center"
    >
        <div style="flex-grow: 1;align-self:center;text-align:center">
            <h2 class="no-line">Welcome to</h2>
            <img src="assets/img/LogoType.svg"
                 alt="VMK Legacy"
                 style="max-width: 400px"
            />
            <p style="max-width: 650px;margin: 0 auto;margin-top: 25px;">
                A fan re-creation of the beloved <b>Virtual Magic Kingdom</b> game, a virtual
                world inspired by Disney theme parks. VMKL is organized by fans and is always free to play.
            </p>
        </div>
    </div>
</section>
