<div
        class="modal modal-container d-flex justify-content-center align-items-center bg-brandgradient-diag row"
        *ngIf="isVisible"
>
    <div class="col-6">
        <div class="inner bg-white">
            <div class="header bg-darkblue color-white">
                <div class="header-container d-flex align-items-center">
                    <span class="font-header">Activate</span>
                    <button
                            type="button"
                            name="close"
                            (click)="closeModal()"
                            class="btn bg-darkblue btn-close"
                    >
                        <div class="close-line-container">
                            <span class="close-line bg-white"></span>
                            <span class="close-line bg-white"></span>
                        </div>
                    </button>
                </div>
            </div>
            <div *ngIf="success">
                <div class="mb-2 text-center">
                    Your email address has been confirmed!
                </div>

                <button type="button"
                        name="close"
                        (click)="closeModal()" class="btn btn-green w-100">
                    Continue
                </button>
            </div>
            <form *ngIf="!success"
                  class="register-form form-container"
                  [formGroup]="form"
                  (ngSubmit)="handleActivate()"
            >
                <div
                        [ngClass]="{ 'has-error': submitErrors }"
                        *ngIf="submitErrors"
                        class="form-group"
                >
                    <div>
                        <em>{{ submitErrors }}</em>
                    </div>
                </div>
                <div
                        [ngClass]="{
            'has-error':
              (isSubmitted && code.errors) || submitErrors
          }"
                        class="form-group"
                >
                    <label for="code">Activation Code</label>
                    <input
                            type="text"
                            placeholder="Activation Code"
                            formControlName="code"
                            id="code"
                    />
                    <div
                            *ngIf="isSubmitted && code.errors"
                            class="help-block"
                    >
                        <div *ngIf="code.errors['required']">
                            <em>Invalid activation code</em>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-green w-100">
                    Activate Account
                </button>
            </form>
        </div>
    </div>
</div>
