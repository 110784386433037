<div id="screenshot-slider">
    <div id="slide-overlay">
        <div id="slide-buttons" class="desktop-only">
            <button (click)="backImage()">◀</button>
            <button (click)="nextImage()">▶</button>
        </div>
    </div>
    <div id="slide-inner" [style]="'left: ' + (currentIndex * -100) + '%'">
        <div *ngFor="let file of files | keyvalue: asIsSort" class="slider-slide" [style]="+file.key === currentIndex || +file.key === currentIndex - 1 ? 'background-image:url(/assets/img/screens/' + file.value + '.jpeg)' : ''"><div><img [src]="'/assets/img/screens/' + file.value + '.jpeg'" [loading]="+file.key <= currentIndex + 1 ? 'eager' : 'lazy'"/></div></div>
    </div>
</div>