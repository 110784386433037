import { Component, OnDestroy, OnInit } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { StatusService } from '../../status.service'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-rules',
    templateUrl: './rules.component.html',
    styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit, OnDestroy {
    private subscription?: Subscription

    constructor(
        private metaService: Meta,
        private statusService: StatusService,
        private router: Router
    ) {

    }

    ngOnInit(): void {
        this.subscription = this.statusService.status.subscribe(() => {
            if (this.statusService.hasRulesArticle()) {
                this.router.navigate(['/kb/page/rules'])
                return
            }
        })
        this.metaService.updateTag({
            name: 'description',
            content: 'The rules are in place for the safety and well-being of our guests.'
        })
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe()
        this.subscription = undefined
    }

}
