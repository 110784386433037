import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { AuthService } from '../../auth.service'

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {
    code = new FormControl('', { nonNullable: true, validators: Validators.required })
    isSubmitted = false
    isVisible = true
    submitErrors: any
    success = false
    form: FormGroup

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService
    ) {
        this.form = this.formBuilder.group({
            code: this.code
        })
    }

    ngOnInit(): void {
        this.isVisible = true

        this.activatedRoute.queryParams.subscribe(params => {
            if (params['code']) {
                this.code.setValue(params['code'])
                this.handleActivate()
            }
        })
    }

    handleActivate(): void {
        if (this.code.invalid) {
            return
        }
        const code = this.code.value
        if (!code) {
            return
        }
        this.isSubmitted = true

        this.authService.activateAccount(code)
            .then(() => {
                this.success = true
            })
            .catch(err => {
                this.success = false
                this.submitErrors = err
            })
    }

    showModal(): void {
        this.isVisible = true
    }

    closeModal(): void {
        this.isVisible = false
        this.router.navigate(['/'])
    }
}
