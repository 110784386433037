import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

import { AppComponent } from './app.component'
import { NavComponent } from './components/nav/nav.component'
import { AppRoutingModule } from './app-routing.module'
import { RulesComponent } from './pages/rules/rules.component'
import { HomeComponent } from './pages/home/home.component'
import { HeroComponent } from './components/hero/hero.component'
import { NewsArticleComponent } from './pages/news-article/news-article.component'
import { LoginComponent } from './components/login/login.component'
import { RegisterComponent } from './components/register/register.component'

import { FooterComponent } from './components/footer/footer.component'
import { RecoverComponent } from './components/recover/recover.component'
import { PassresetComponent } from './components/passreset/passreset.component'
import { TermsComponent } from './pages/terms/terms.component'

import { AuthService } from './auth.service'
import { VMKLegacyAPI } from '@vmk-legacy/common-ts'
import { CookieService } from 'ngx-cookie-service'
import { ActivateComponent } from './components/activate/activate.component'
import { environment } from '../environments/environment'
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component'
import { KbComponent } from './pages/kb/kb.component'
import { ProfileComponent } from './pages/profile/profile.component'
import { ScreenshotSliderComponent } from './components/screenshot-slider/screenshot-slider.component'
import { SidebarComponent } from './components/sidebar/sidebar.component'
import { KbPageComponent, SafeHtmlPipe } from './pages/kb-page/kb-page.component'
import { LatestNewsComponent } from './latest-news/latest-news.component'
import { LightboxComponent } from './components/lightbox/lightbox.component'
import { EventsComponent } from './pages/events/events.component'
import { HelpComponent } from './pages/help/help.component'
import { RedirectGuard } from './redirect.guard'
import posthog from 'posthog-js'

@NgModule({
    declarations: [
        AppComponent,
        NavComponent,
        RulesComponent,
        HomeComponent,
        HeroComponent,
        NewsArticleComponent,
        LoginComponent,
        RegisterComponent,
        FooterComponent,
        RecoverComponent,
        PassresetComponent,
        TermsComponent,
        ActivateComponent,
        SafeHtmlPipe,
        PagenotfoundComponent,
        KbComponent,
        ProfileComponent,
        ScreenshotSliderComponent,
        SidebarComponent,
        KbPageComponent,
        LatestNewsComponent,
        LightboxComponent,
        EventsComponent,
        HelpComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    providers: [AuthService, {
        provide: VMKLegacyAPI,
        useValue: new VMKLegacyAPI(environment.API_URL, true, posthog)
    }, CookieService, RedirectGuard],
    bootstrap: [AppComponent]
})
export class AppModule {
}
