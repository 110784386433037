import { Component } from '@angular/core'
import { APIKBItemResults, EItemType, VMKLegacyAPI } from '@vmk-legacy/common-ts'
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router'
import { NgForOf, NgIf } from '@angular/common'
import { environment } from '../../../environments/environment'
import { FormsModule } from '@angular/forms'
import { DateTime } from 'luxon'
import { Meta } from '@angular/platform-browser'

@Component({
    selector: 'app-kb-index',
    standalone: true,
    imports: [
        NgForOf,
        NgIf,
        RouterLink,
        FormsModule
    ],
    templateUrl: './kb-index.component.html',
    styleUrl: './kb-index.component.scss'
})
export class KbIndexComponent {

    results?: APIKBItemResults
    query: string = ''
    lastQuery: string = ''
    type: string = ''
    sort: string = 'release-desc'
    page?: number
    error?: string

    constructor(
        private vmklAPI: VMKLegacyAPI,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private metaService: Meta
    ) {

    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            this.search(params['query'] ?? '', params['type'], params['sort'], params['page'])
        })
        this.metaService.updateTag({
            name: 'description',
            content: 'Learn more about the clothing, furniture, and pins released on VMK Legacy.'
        })
    }

    search(term: string, type?: string, sort?: string, page?: number): void {
        this.query = term
        this.type = type ?? ''

        if (this.query && this.query.length < 3) {
            this.error = 'Please enter a search 3 characters or longer'
            this.results = undefined
            return
        }
        if (this.lastQuery !== term) {
            this.page = 1
            page = 1
        }

        const [_, sortField, sortDir] = (sort ?? 'release-desc').match(/([a-z]*)-(asc|desc)/) as string[]

        this.lastQuery = ''
        this.error = undefined

        this.vmklAPI.getKBItemResults({ term, type, sort: sortField ?? 'release', asc: sortDir === 'asc' ? 1 : 0, page }).then(results => {
            console.log(results)
            this.lastQuery = term
            this.results = results
        })
    }

    navigateToSearch(): void {
        const queryParams: Params = { query: this.query, type: this.type, sort: this.sort, page: this.page }

        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            }
        )
    }

    gotoPage(page: number): void {
        this.page = page
        this.navigateToSearch()
    }

    numPages(): number {
        if (!this.results) {
            return 0
        }
        return Math.ceil(this.results.total / this.results.perPage)
    }

    currentQueryParams(): object {
        return { back: location.href.replace(location.origin, '') }
    }

    protected readonly EItemType = EItemType
    protected readonly environment = environment
    protected readonly DateTime = DateTime
}
