import { Component, OnInit } from '@angular/core'
import { APIPlayerBySessionResponse } from '@vmk-legacy/common-ts'
import { StatusService } from '../../status.service'

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
    showNav = false
    user?: APIPlayerBySessionResponse
    navPages: {
        slug: string
        title: string
    }[] = []

    constructor(private statusService: StatusService) {
    }

    ngOnInit(): void {
        this.statusService.status.subscribe(status => {
            if (status?.homeSlots) {
                this.navPages = status.homeSlots['1'] ?? []
            }
        })
    }

    onToggleNav(): void {
        this.showNav = this.showNav ? false : true
    }

    hasRulesArticle(): boolean {
        return this.statusService.hasRulesArticle()
    }

    kbActive(): boolean {
        return new Date() > new Date('2024-07-31T12:45:00+0000')
    }
}
