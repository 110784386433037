import { Component, OnInit } from '@angular/core'
import { Meta } from '@angular/platform-browser'

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

    constructor(private metaService: Meta) {}

    ngOnInit(): void {
        this.metaService.updateTag({
            name: 'description',
            content: 'Having trouble in the Kingdom? Read our troubleshooting tips and learn how to get help from a VMK Legacy staff member.'
        })
    }
}