<div
        class="modal modal-container d-flex justify-content-center align-items-center bg-brandgradient-diag row"
        *ngIf="isVisible"
>
    <div class="col-6">
        <div class="inner bg-white">
            <div class="header bg-darkblue color-white">
                <div class="header-container d-flex align-items-center">
                    <span class="font-header">Recover Password</span>
                    <button
                            type="button"
                            name="close"
                            (click)="closeModal()"
                            class="btn bg-darkblue btn-close"
                    >
                        <div class="close-line-container">
                            <span class="close-line bg-white"></span>
                            <span class="close-line bg-white"></span>
                        </div>
                    </button>
                </div>
            </div>
            <form
                    [formGroup]="recoverForm"
                    class="register-form form-container"
                    (ngSubmit)="handleRecover()"
                    *ngIf="!recoverSent"
            >
                <p>
                    Enter the email address you used to sign up, and we will send you an email with instructions to reset your password.
                </p>
                <div
                        [ngClass]="{ 'has-error': submitErrors }"
                        *ngIf="submitErrors"
                        class="form-group"
                >
                    <div>
                        <em>{{ submitErrors }}</em>
                    </div>
                </div>
                <div
                        [ngClass]="{
            'has-error':
              (isSubmitted && formControls['email'].errors) || submitErrors
          }"
                        class="form-group"
                >
                    <label for="email">Email</label>
                    <input
                            id="email"
                            type="email"
                            placeholder="Email"
                            formControlName="email"
                            email
                    />
                    <div
                            *ngIf="isSubmitted && formControls['email'].errors"
                            class="help-block"
                    >
                        <div *ngIf="formControls['email'].errors['required']">
                            <em>Email is required</em>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-green w-100">Submit</button>
            </form>
            <div *ngIf="recoverSent" class="w-100 recover-sent">
                <p class="text-center">Please check your email for a reset link.</p>
                <button
                        type="button"
                        name="close"
                        (click)="closeModal()"
                        class="btn-gray btn register btn-register"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
