<button id="enter-game" (click)="launchClient()" class="desktop-only" *ngIf="user?.user">
    <div id="animated-castle"></div>
    <span>PLAY NOW</span>
</button>
<button (click)="launchClient()" class="mobile-only btn btn-green w-100" *ngIf="user">PLAY NOW</button>
<div *ngIf="!user">
    <app-login></app-login>
</div>
<div *ngIf="status && (status.schedule.hours || status.numOnline)" style="padding: 10px 5px;margin-bottom:10px;text-align: center;color:#fff">
    <div *ngIf="status.schedule.hours">Open Today<br/><b style="font-size: 125%">{{ status.schedule.hours.open }} - {{ status.schedule.hours.close }} ET</b></div>
    <div *ngIf="status.numOnline && status.numOnline > 5">
        <span>{{ status.numOnline }} Players Online</span>
    </div>
</div>
<div *ngIf="profile" class="bg-darkblue" style="border-radius: 7px 25px;padding-top:25px;padding-bottom: 10px">
    <div *ngIf="profile.avatarUrl">
        <div style="position:relative">
            <img *ngIf="profile.avatarUrl"
                 src="{{ profile.avatarUrl.replace('direction=4', 'direction=3') }}&anim=sit"
                 onerror="this.style.display = 'none'"
                 style="height: 125px;position:absolute;top: -60px;left: 0"
            />
            <div style="display: inline-block;vertical-align: top;text-align:center;padding-left: 55px">
                <b>{{ profile.ign }}</b>
                <div style="margin-top:10px">
                    <img *ngFor="let badge of profile.badges" [src]="'/assets/img/badges/badge_' + badge + '.png'" />
                </div>
            </div>
        </div>
        <div id="pin-contain" style="margin-bottom: 5px" class="desktop-only">
            <div class="profile-pin" *ngFor="let pin of profile.lanyard"
                 [title]="pin.name ?? ''">
                <img [src]="environment.THUMBS_URL + '/item_' + pin.defUid + '-sm.png'" />
            </div>
            <div class="profile-pin" *ngFor="let pin of lanyardFill"></div>
        </div>
    </div>
    <div *ngIf="!profile.avatarUrl">
        <b>Enter the game to create your character!</b><br/><br/>
    </div>
    <div *ngIf="user && user.links" style="display:flex;flex-direction:column">
        <a *ngFor="let item of user.links | keyvalue" class="btn bg-white" style="margin: 3px 20px"
           href="{{ item.value }}">
            {{item.key}}
        </a>
        <a class="btn bg-white" style="margin: 3px 20px" href="#" (click)="logout()">Logout</a>
    </div>
</div>

