import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss']
})
export class HeroComponent {
    @Input() pageTitle: string = ''
    @Input() bgImg: string = 'assets/img/rooms/castle_moneyshot_bg.png'
    @Input() bgImageAlt: string = 'VMK Castle Gardens with the 50th anniversary castle overlay'

}
