import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { RulesComponent } from './pages/rules/rules.component'
import { NewsArticleComponent } from './pages/news-article/news-article.component'
import { HomeComponent } from './pages/home/home.component'
import { TermsComponent } from './pages/terms/terms.component'

import { ActivateComponent } from './components/activate/activate.component'
import { RegisterComponent } from './components/register/register.component'
import { RecoverComponent } from './components/recover/recover.component'
import { PassresetComponent } from './components/passreset/passreset.component'
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component'
import { KbComponent } from './pages/kb/kb.component'
import { ProfileComponent } from './pages/profile/profile.component'
import { HelpComponent } from './pages/help/help.component'
import { KbPageComponent } from './pages/kb-page/kb-page.component'
import { LightboxComponent } from './components/lightbox/lightbox.component'
import { EventsComponent } from './pages/events/events.component'
import { KbIndexComponent } from './pages/kb-index/kb-index.component'
import { RedirectGuard } from './redirect.guard'

const routes: Routes = [
    {
        path: '', component: HomeComponent, title: 'VMK Legacy',
        children: [
            { path: 'auth/activate', component: ActivateComponent },
            { path: 'auth/register', component: RegisterComponent },
            { path: 'auth/recover', component: RecoverComponent },
            { path: 'auth/reset', component: PassresetComponent },
            { path: 'lightbox/:url', component: LightboxComponent },
        ]
    },
    { path: 'welcome/activate', redirectTo: 'auth/activate' },
    { path: 'activate', redirectTo: 'auth/activate' },
    { path: 'welcome', redirectTo: '' },
    { path: 'home', redirectTo: '' },
    {
        path: 'bug-report',
        canActivate: [RedirectGuard],
        component: HomeComponent,
        data: {
            externalUrl: 'https://www2.vmklegacy.com/bug-report'
        }
    },

    { path: 'rules', component: RulesComponent, title: 'Rules | VMK Legacy' },
    { path: 'help', component: HelpComponent, title: 'Get Help | VMK Legacy' },
    {
        path: 'events', component: EventsComponent, title: 'Events | VMK Legacy',
        children: [
            { path: 'lightbox/:url', component: LightboxComponent }
        ]
    },
    {
        component: NewsArticleComponent, title: 'News | VMK Legacy',
        matcher: (url) => {
            if (url.length === 1 && url[0].path.match(/^news(?!\/).*/)) {
                return { consumed: url }
            }

            return null
        },
    },
    { path: 'news!', component: NewsArticleComponent, title: 'News | VMK Legacy' },
    { path: 'news/:articleId', component: NewsArticleComponent },
    { path: 'terms', component: TermsComponent, title: 'Terms of Service | VMK Legacy' },
    { path: 'kb', component: KbIndexComponent, title: 'Knowledge Base | VMK Legacy' },
    { path: 'kb/shops', component: KbComponent, title: 'Shop Catalog | VMK Legacy' },
    { path: 'kb/page/:category/:slug', component: KbPageComponent, title: 'Knowledge Base | VMK Legacy' },
    { path: 'kb/page/:slug', component: KbPageComponent, title: 'Knowledge Base | VMK Legacy' },
    { path: 'kb/items/:itemUid', component: KbPageComponent, title: 'Knowledge Base | VMK Legacy' },

    { path: 'profile/:ign', component: ProfileComponent },

    { path: '**', component: PagenotfoundComponent, title: 'Page Not Found | VMK Legacy' }
]

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
