import { Component } from '@angular/core'
import { ProfileService } from '../../profile.service'
import { ActivatedRoute } from '@angular/router'
import { APIPlayerProfile } from '@vmk-legacy/common-ts'
import { environment } from '../../../environments/environment'

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
    profile?: APIPlayerProfile

    constructor(private profileService: ProfileService, private route: ActivatedRoute) {
        this.route.params.subscribe(routeParams => {
            const profileIgnFromRoute = String(routeParams['ign'])
            if (profileIgnFromRoute) {
                this.profileService.getProfile(profileIgnFromRoute).then((profile) => {
                    if (profile) {
                        this.profile = profile
                    }
                })
            }
        })
    }

    protected readonly environment = environment
}
