<h1>VMK Legacy Rules</h1>
<img src="/assets/img/players/bellhopgirl.png" style="float:right;margin-left:20px"/>
<p>
    The VMK Legacy ("VMKL") rules are in place for the safety and well-being of our guests. Failure to
    adhere to
    these rules will result in points applied to your account and/or the issuance of temporary or permanent
    bans.
    Report any rule-breaking behavior via the Call for Help tool.
</p>
<ol>
    <li>
        Inappropriate content and topics are not allowed. This includes, but is not limited to, obscene,
        racist,
        abusive, offensive, threatening, harassing, and sexually explicit language and content, as well as
        hate
        speech. Do not attempt to circumvent or bypass the in-game dictionary (“dictionary dancing”).
    </li>
    <li>
        No solicitations or advertisements are allowed. These include
        advertisements for your business or latest get-rich-quick scheme. Such
        notes add clutter and devalue conversation.
    </li>
    <li>
        Excessive spamming of the chat for the purpose of disruption is not allowed.
    </li>
    <li>
        Do not suggest, encourage, or engage in illegal or law-breaking activity. We will assist law
        enforcement
        officials in regards to any illegal or law-breaking behavior.
    </li>
    <li>
        Do not share sensitive personal information, including but not limited to: phone numbers, addresses,
        and Social Security numbers. Do not share any personal information of anyone other than yourself,
        sensitive
        or not. Share at your own risk; you are solely responsible for any of your own information you
        choose to tell
        others.
    </li>
    <li>
        Do not impersonate or pretend to be other players or VMKL staff members.
    </li>
    <li>
        Multiple account abuse is prohibited. You may have one additional ("clone") account aside from your
        main
        account. The additional account may not be used to gain any advantages over other players. This
        includes,
        but is not limited to, gaining additional quest and event prizes, gaining additional unique
        per-player items,
        and entering contests on multiple accounts. You may not effectively use another person as a clone,
        even if
        you are not logging onto multiple accounts.
    </li>
    <li>
        You may not log on to an account that is not your own to participate in events, quests, or contests
        for
        another person. You are responsible for any chat or actions made on your account. You are encouraged
        to
        keep personal account information private, including passwords.
    </li>
    <li>
        Off-site trading and selling VMKL items/accounts is prohibited. You may not trade or sell VMKL
        items or credits for items, currencies, goods, or services existing outside of VMK Legacy. This
        includes,
        but is not limited to, items from other games, commissions, and real money. Suspicious numbers of
        one-sided
        trades may be flagged as off-site trading and/or considered abuse of multiple accounts. Consequences
        may
        include bans, trade reversals, and/or loss of items for all parties involved.
    </li>
    <li>Do not scam other players. Do not be deceitful or misleading to acquire items or credits. You are
        responsible for any trades you accept.
    </li>
    <li>Do not attempt to evade bans by using other accounts. You must serve the full length of any ban
        issued.
    </li>
    <li>Do not abuse the Call for Help tool. Do not spam, send false reports, or file non-urgent bug reports
        using
        the Call for Help tool.
    </li>
    <li>Do not exploit or abuse bugs or glitches in the game. Report any players exploiting bugs or glitches
        using
        the Call for Help tool. Bug reports should be submitted via the <a
                href="https://www2.vmklegacy.com/bug-report"
                target="_blank"><strong>bug reporting form</strong></a>.
    </li>
    <li>Modifying the game, intentionally trying to break it, or using supplemental tools to reverse
        engineer it or
        give yourself an advantage is prohibited. If you have a concern, it should be raised with staff --
        even with
        good intentions, performing your own security testing without staff permission is not allowed and is
        cause for
        a ban.
    </li>
    <li>Use good judgment and common sense. VMKL staff reserve the right to take action for any other
        inappropriate
        situations not specifically mentioned in these rules. These rules are subject to change at the
        discretion of
        VMKL staff, with notice.
    </li>
    <li>
        By use of this site and the VMK Legacy game, you agree to abide by the
        VMK Legacy <a href="/terms"><strong>Terms of Service</strong></a
    >.
    </li>
</ol>
<h2 class="mt-5">
    Other Policies
</h2>
<ul>
    <li>You may request a name change no more than once every 6 months via the In-Game Name tab of the Help
        button.
    </li>
    <li>
        Effective 2/1/2023, In-Game Names may be forfeited if requested by another player and the account
        occupying
        the IGN has not logged in within the last rolling year.
    </li>
</ul>
<h2 class="mt-5">The Moderation System</h2>
<p>
    To ensure fair moderation of our guests, VMK Legacy staff use a tiered point system to address each
    offense.
</p>
<p>
    Each offense is tied to a tier, which is associated with a set of points.
</p>
<ul>
    <li>Points are valid for a 12 month rolling window</li>
    <li>Automatic 24-hour ban starts at 15 points</li>
    <li>
        Additional points after a 24-hour ban will result in an immediate, longer ban.
    </li>
    <li>
        If a previous ban is on record, but all points have expired, 24-hour bans will start at 10 points.
    </li>
    <li>Excessive numbers of bans or serious offenses will result in a permanent ban.</li>
</ul>
<div class="row mt-4">
    <div class="col-4">
        <div class="inner bg-purple rules">
            <small>TIER 1</small>
            <h2>5 points</h2>
            <ul>
                <li>Abusing or spamming Call for Help</li>
                <li>Impersonation of staff or others</li>
                <li>Dictionary dancing</li>
                <li>Public disruption</li>
                <li>Spamming chat</li>
            </ul>
        </div>
    </div>
    <div class="col-4">
        <div class="inner bg-lightpurple rules">
            <small>TIER 2</small>
            <h2>10 points</h2>
            <ul>
                <li>Stealing/scamming/trickery</li>
                <li>Personal information violations</li>
                <li>Off-site trading/selling</li>
                <li>Exploiting glitches/botting</li>
                <li>Harassment/bullying</li>
                <li>Multiple account abuse</li>
            </ul>
        </div>
    </div>
    <div class="col-4">
        <div class="inner bg-pink rules">
            <small>TIER 3</small>
            <h2>15 points</h2>
            <ul>
                <li>Racism/discrimination</li>
                <li>Hate speech</li>
                <li>Adult/sexual themes</li>
                <li>Threats of violence</li>
                <li>Law breaking</li>
                <li>Ban evasion (auto-extension of ban)</li>
            </ul>
        </div>
    </div>
</div>