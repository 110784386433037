import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../auth.service'
import { environment } from '../../../environments/environment'
import { Meta } from '@angular/platform-browser'

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    isLogged = false

    constructor(private authService: AuthService, private metaService: Meta) {
    }

    ngOnInit(): void {
        this.metaService.updateTag({
            name: 'description',
            content: 'A recreation of Disney\'s Virtual Magic Kingdom game. Create a character, make friends, build rooms, and become the envy of the Kingdom!'
        })

        this.authService
            .currentUser
            .subscribe(user => {
                this.isLogged = !!user
            })
    }

    protected readonly environment = environment
}
