import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { AuthService } from '../../auth.service'
import { APIPlayerBySessionResponse } from '@vmk-legacy/common-ts'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    authForm = this.formBuilder.group({
        email: new FormControl('', { validators: Validators.required, nonNullable: true }),
        password: new FormControl('', { validators: Validators.required, nonNullable: true }),
        remember: new FormControl(true),
    })
    isSubmitted = false
    submitErrors: any
    user?: APIPlayerBySessionResponse
    randomPlayer: string

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder
    ) {
        const players = ['conehatgirl', 'orangeguy', 'pinkgirl', 'pirate', 'space', 'mikehat']
        this.randomPlayer = players[Math.floor(Math.random() * players.length)]
    }

    ngOnInit(): void {
        this.authService.currentUser.subscribe(user => this.user = user)
    }

    get formControls () { /* eslint-disable-line @typescript-eslint/explicit-function-return-type */
        return this.authForm.controls
    }

    login(): void {
        if (this.authForm.invalid) {
            return
        }
        const creds = this.authForm.value
        if (!creds.email || !creds.password) {
            this.submitErrors = 'Please try again.'
            return
        }
        this.isSubmitted = true
        this.authService
            .login(creds as Required<typeof creds>)
            .catch(err => {
                console.log('error found', err)
                this.submitErrors = err
            })
    }

    logout(): void {
        this.isSubmitted = false

        this.authService.logout()
            .catch(err => {
                console.log('error found', err)
                this.submitErrors = err
            })
    }
}
