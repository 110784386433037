import { Component, OnInit } from '@angular/core'
import { NewsService } from '../../news.service'
import { APIArticleFull, APIArticleList, APIArticleListItem } from '@vmk-legacy/common-ts'
import { ActivatedRoute } from '@angular/router'
import { Meta, Title } from '@angular/platform-browser'

@Component({
    selector: 'app-news',
    templateUrl: './news-article.component.html',
    styleUrls: ['./news-article.component.scss']
})
export class NewsArticleComponent implements OnInit {
    article?: APIArticleFull
    otherArticles: APIArticleListItem[] = []
    articleIsLatest = false
    articleStatus = 'Loading...'

    constructor(private newsService: NewsService, private route: ActivatedRoute, private titleService: Title, private metaService: Meta) {
        this.route.params.subscribe(routeParams => {
            const articleIdFromRoute = Number(routeParams['articleId'])
            if (!isNaN(articleIdFromRoute) && articleIdFromRoute) {
                this.loadArticle(articleIdFromRoute)
            }
        })
    }

    ngOnInit(): void {
        const routeParams = this.route.snapshot.paramMap
        const showLatest = !routeParams.has('articleId')

        if (showLatest) {
            this.newsService.latestArticle.subscribe(article => {
                if (article) {
                    this.titleService.setTitle(article.title + ' | VMK Legacy')
                    this.metaService.updateTag({ name: 'description', content: article.preview })
                    this.fetchArchive(article.id)
                } else {
                    this.fetchArchive()
                }
                this.article = article
                this.articleIsLatest = true
            })
        } else {
            const articleIdFromRoute = Number(routeParams.get('articleId'))
            if (!isNaN(articleIdFromRoute) && articleIdFromRoute) {
                this.loadArticle(articleIdFromRoute)
            }
        }
    }

    loadArticle(id: number): void {
        this.newsService.getArticle(id).then((article) => {
            if (article) {
                this.titleService.setTitle(article.title + ' | VMK Legacy')
                this.metaService.updateTag({ name: 'description', content: article.preview })
                this.article = article
            } else {
                this.articleStatus = 'Article not found.'
            }

            this.articleIsLatest = id == this.newsService.latestNewsSource.getValue()?.id

            this.fetchArchive(id)
        })
    }

    fetchArchive(beforeId?: number): void {
        this.newsService.getArticleList(1, beforeId).then((a: APIArticleList | undefined) => {
            if (a?.articles) {
                this.otherArticles = a.articles
            }
        })
    }

    sanitizeImageUrl(url: string): string {
        return url.replace('[[', '').replace(']]', '')
    }

}
