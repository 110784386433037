import { Component } from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { passConfirmValidator } from '../passreset/passreset.component'
import { AuthService } from '../../auth.service'

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
    registerForm = this.formBuilder.group({
        email: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
        password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
        password_confirmation: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
        // 'g-recaptcha-response': new FormControl('', { nonNullable: true, validators: [Validators.required] }),
        agree_terms: new FormControl(false, { nonNullable: true, validators: [Validators.required] })
    }, { validators: [passConfirmValidator] })
    isSubmitted = false
    isVisible = true
    registerSuccess = false
    submitErrors: any

    constructor(
        private authService: AuthService,
        private router: Router,
        private formBuilder: FormBuilder
    ) {
    }

    get formControls() { /* eslint-disable-line @typescript-eslint/explicit-function-return-type */
        return this.registerForm.controls
    }

    handleRegister(): void {
        console.log('register')
        this.isSubmitted = true
        if (this.registerForm.invalid) {
            return
        }

        this.authService
            .register(this.registerForm.getRawValue())
            .then(data => {
                console.log(data)
                this.registerSuccess = true
            })
            .catch(err => {
                this.submitErrors = err
                this.registerSuccess = false
            })
    }

    updateConfirmValidator(): void {
        Promise.resolve().then(() =>
            this.registerForm.controls['password_confirmation'].updateValueAndValidity())
    }

    resolved(captchaResponse: string): void {
        console.log(`Resolved response token: ${captchaResponse}`)
    }

    showModal(): void {
        this.isVisible = true
    }

    closeModal(): void {
        this.isVisible = false
        this.router.navigate(['/'])
    }
}
