import { Component, OnInit } from '@angular/core'
import { environment } from '../../../environments/environment'
import { CookieService } from 'ngx-cookie-service'
import { StatusService } from '../../status.service'
import { AuthService } from '../../auth.service'
import { ProfileService } from '../../profile.service'
import { APIPlayerBySessionResponse, APIPlayerProfile, APIStatus, VMKLegacyAPI } from '@vmk-legacy/common-ts'

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    clientUrl = environment.CLIENT_URL
    status?: APIStatus
    profile?: APIPlayerProfile
    lanyardFill = Array(15)
    user?: APIPlayerBySessionResponse
    clientWindow: Window | null = null

    constructor(
        private cookieService: CookieService,
        private statusService: StatusService,
        private authService: AuthService,
        private profileService: ProfileService,
        private vmklAPI: VMKLegacyAPI
    ) {

    }

    ngOnInit(): void {
        this.statusService
            .status
            .subscribe(status => (this.status = status))

        this.authService.currentUser.subscribe(user => {
            this.user = user
            if (user) {
                this.profileService.getProfile(user.user.ign).then(profile => {
                    if (profile) {
                        this.profile = profile
                        this.lanyardFill = Array(15 - profile.lanyard.length)
                    }
                })
            } else {
                this.profile = undefined
            }
        })
    }

    logout(): void {
        this.authService.logout()
    }

    launchClient(): void {
        if (this.clientWindow && !this.clientWindow.closed) {
            this.clientWindow.focus()
            return
        }

        const width = 800 * 1.25
        const height = 600 * 1.25
        const left = window.screenX + (window.outerWidth / 2) - ((width / 2) + 10)
        const top = window.screenY + (window.outerHeight / 2) - ((height / 2) + 50)

        let clientUrl = environment.CLIENT_URL + '?ref=home'
        if (this.vmklAPI.apiToken) {
            clientUrl += '&at=' + this.vmklAPI.apiToken
        }

        this.clientWindow = window.open(clientUrl, 'vmkl-client', 'width=' + width + ',height=' + height + ',left=' +
            left + ',top=' + top + ',screenX=' + left + ',screenY=' +
            top + ',popup=true')
    }

    protected readonly environment = environment
}