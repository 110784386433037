import { Component } from '@angular/core'
import { Router } from '@angular/router'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'vmkl-home'

    constructor(readonly router: Router) {}

    isHome(): boolean {
        return this.router.url === '/'
    }

    onActivate(): void {
        window.scroll(0, 0)
    }
}