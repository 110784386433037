<div class="container-fluid pb-5 news">
    <div class="row pb-5">
        <div class="col-6">
            <div *ngIf="shops">
                <h2>Shop Catalog</h2>
                <a [routerLink]="'/kb'">« Item Search</a>
                <br/>
                <p>
                    This page shows the current inventory of the different VMK Legacy shops.
                </p>
                <label><b>View shop: </b> <select [(ngModel)]="currentShop">
                    <option *ngFor="let shop of shops; let i = index" [value]="i">{{ shop.name }}</option>
                </select></label><br/><br/>
                <div *ngIf="shops[currentShop].catalog_items.length">
                    <h3>{{ shops[currentShop].name }}</h3>
                    <div *ngIf="shops[currentShop].require_unlock">
                        Did you know? This shop's catalog won't appear in Shopping until you have
                        visited it at least once. Items marked (Special) can only be viewed from the Shopping
                        Specials tab while you're in the shop.
                        <br/>
                        <br/>
                    </div>
                    <table style="width: 100%" cellspacing="0">
                        <tr>
                            <th class="text-left"><small>Type</small></th>
                            <th></th>
                            <th class="text-left">Name</th>
                            <th class="text-right">Credits</th>
                        </tr>
                        <tr *ngFor="let release of shops[currentShop].catalog_items">
                            <td>
                                <small *ngIf="release.item_type === EItemType.Pin">Pin</small>
                                <small *ngIf="release.item_type === EItemType.Clothing">Clothing</small>
                                <small *ngIf="release.item_type === EItemType.Furniture">Furniture</small>
                                <small *ngIf="release.item_type === EItemType.Room">Room</small>
                                <small *ngIf="release.item_type === EItemType.Pack">Pack</small>
                                <small *ngIf="release.item_type === EItemType.Poster">Poster</small>
                            </td>
                            <td>
                                <small *ngIf="release.is_special">(Special)</small>
                            </td>
                            <td>
                                <span *ngIf="release.item_type === EItemType.Room">{{ release.space_def?.name }}</span>
                                <span>{{ release.item_def?.name }}</span>
                                <span *ngIf="release.item_def?.pin_is_single_use"> (single use)</span>
                            </td>
                            <td class="text-right">{{ release.credit_price }}</td>
                        </tr>
                    </table>
                    <br>
                    <div>
                        Information updated {{ updateTime }}.
                        Shop releases are subject to change at any time. For more information, keep an eye on our <a
                            routerLink="/news">newsletter</a>
                        and <a href="https://instagram.com/vmklegacy">Instagram</a>.
                    </div>
                    <br>
                </div>
            </div>
            <div *ngIf="!shops">
                <b>Loading shop release data...</b>
            </div>
        </div>
    </div>
</div>
