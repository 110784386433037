import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { APIArticleCat, APIArticleFull, APIArticleList, VMKLegacyAPI } from '@vmk-legacy/common-ts'

@Injectable({
    providedIn: 'root'
})
export class NewsService {
    private newsArticleSource = new BehaviorSubject<APIArticleFull | undefined>(undefined)
    article = this.newsArticleSource.asObservable()

    latestNewsSource = new BehaviorSubject<APIArticleFull | undefined>(undefined)
    latestArticle = this.latestNewsSource.asObservable()

    constructor(private vmklAPI: VMKLegacyAPI) {
        console.log('NewsService constructor')
        this.getLatestArticle()

        setInterval(() => this.getLatestArticle(), 1000 * 60 * 60)
    }

    async getArticleList(page: number = 1, before?: number): Promise<APIArticleList> {
        if (location.href.includes('localhost')) {
            return new Promise<APIArticleList>(resolve => {
                const data: APIArticleList = {
                    currentPage: 1,
                    lastPage: 1,
                    nextPageUrl: null,
                    total: 1,
                    previousPageUrl: null,
                    perPage: 1,
                    articles: [{
                        title: 'Testing Article Title',
                        id: 0,
                        preview: 'Testing article description.',
                        feature_image: null,
                        posted_at: '2023-06-01T13:00:00.000000Z'
                    }]
                }
                resolve(data)
            })
        }
        return await this.vmklAPI.listArticles(page, before)
    }

    getArticle(id: number): Promise<APIArticleFull> {
        if (location.href.includes('localhost')) {
            return new Promise<APIArticleFull>(resolve => {
                const data: APIArticleFull = {
                    title: 'Testing Article Title',
                    id: 0,
                    preview: 'Testing article description!',
                    feature_image: null,
                    body: 'hello',
                    category: APIArticleCat.Newsletter,
                    posted_at: '2020-01-01'
                }
                this.newsArticleSource.next(data)

                resolve(data)
            })
        }
        return this.vmklAPI.getArticle(id).then(data => {
            this.newsArticleSource.next(data)
            return data
        })
    }

    getLatestArticle(): Promise<APIArticleFull> {
        if (location.href.includes('localhost')) {
            return new Promise<APIArticleFull>(resolve => {
                const data: APIArticleFull = {
                    title: 'Testing Article Title',
                    id: 0,
                    preview: 'Testing article description.',
                    feature_image: null,
                    body: 'hello',
                    category: APIArticleCat.Newsletter,
                    posted_at: '2020-01-01'
                }
                this.latestNewsSource.next(data)

                resolve(data)
            })
        }
        return this.vmklAPI.getLatestArticle().then(data => {
            this.latestNewsSource.next(data)
            return data
        })
    }
}
