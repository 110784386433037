<div *ngIf="activeCampaign();let campaign" style="height: 300px;z-index: 1">
    <div [style]="'background-image: url(' + campaign.coverImage + ');height:400px;text-shadow:1px 1px 15px #000;margin: -20px;position:relative;background-size:cover;background-position:center'">
        <div style="height:300px;display:flex;justify-content:center;align-items:center;">
            <div style="background: rgba(0, 0, 0, .4);backdrop-filter: blur(10px);display: inline-block;padding: 20px 30px;border-radius: 5px 25px">
                <h2>{{ campaign.name}}</h2>
                <small>{{ getEventScheduleText(campaign, true) }}</small>
                <p>{{campaign.shortDescription}}</p>
            </div>
        </div>
        <div style="background-image: linear-gradient(0deg, #3e1673, transparent);height: 75px;position:absolute;bottom:0;left:0;width:100%"></div>
    </div>
</div>
<div class="row" style="z-index:2;position:relative">
    <div class="col-4">
        <div class="bg-darkblue inner" style="padding: 0 20px;display:block">
            <h3 class="text-center" style="width:100%;margin-bottom:0">VMK Legacy Events</h3>

            <div style="display: flex;align-items:center;gap: 15px">
                <img src="assets/img/roomjudgingflag.png" style="width:30px"/>
                <div>
                    <p>
                        We have a team of staff members who host special events for players.
                        Events can be rides, games, shows, or just general hang outs!
                    </p>
                    <p>
                        Keep an eye on the upcoming events so you don't miss out. We generally schedule a
                        couple weeks in advance,
                        subject to change.
                    </p>
                    <p>
                        Unless otherwise noted, events take place in the game. On the map, click <b>Guest Rooms</b> and
                        look
                        for a room with this icon: <img src="assets/img/icons/eventroom_icon.png"
                                                        style="vertical-align: middle"/>
                    </p>
                </div>
            </div>
            <h3 class="text-center" style="width:100%;margin-bottom:0">Participation</h3>

            <div style="display: flex;align-items:center;gap: 15px">
                <img src="assets/img/rng.png" style="width:30px"/>
                <p>
                    Make sure to read the event's instructions beforehand so you know how to participate and be eligible
                    for prizes, if any.
                </p>
            </div>
            <h3 class="text-center" style="width:100%;margin-bottom:0">Help During Events</h3>

            <div style="display: flex;align-items:center;gap: 15px">
                <img src="assets/img/animdesk.png" style="width:30px"/>
                <p>
                    Need assistance during an event? Send a Call for Help in-game, or reach out to an online staff
                    member.
                </p>
            </div>
        </div>
    </div>
    <div class="col-8" style="display: grid;gap:15px;align-content: space-between">
        <div *ngIf="!hasEvents()" style="text-align:center;margin-top: 50px;">
            No upcoming events have been revealed yet. Check back later!
        </div>
        <ng-container *ngFor="let event of upcomingEvents()">
            <div *ngIf="!event.isCampaign && scheduleStatus(event);let status"
                 class="inner"
                 [class]="{ now: 'bg-lightpurple', past: 'bg-darkblue', cancelled: 'bg-darkblue', future: 'bg-purple' }[status]"
                 style="padding-bottom:0"
                 [style]="status === 'now' ? 'box-shadow: 0 2px 15px rgba(0, 0, 0, .3);font-size:150%' : ''"
            >
                <div style="display:flex;justify-content: space-between;width:100%">
                    <div>
                        <div class="d-flex"
                             style="gap: 15px;align-items: center;align-content: flex-start;margin-bottom:15px">
                            <h3 style="margin: 0;display:inline">{{event.name}}</h3>
                            <span style="border-radius: 25px;background: #3e1673;padding:6px 13px;text-transform: uppercase;font-size: 12px;letter-spacing: 1px;vertical-align: middle;word-break: keep-all;display:inline-block">
                                <ng-container *ngIf="status === 'now'">Happening Now</ng-container>
                                <ng-container *ngIf="status === 'past'">Past Event</ng-container>
                                <ng-container *ngIf="status === 'cancelled'">Cancelled</ng-container>
                                <ng-container *ngIf="status === 'future'">{{ upcomingRelative(event) }}</ng-container>
                            </span>
                        </div>
                        <table style="font-size: 12px;">
                            <tr>
                                <td style="font-weight:bold;opacity: .6;height:25px">When:</td>
                                <td></td>
                                <td>{{ getEventScheduleText(event, true) }}</td>
                            </tr>
                            <tr *ngIf="event.host">
                                <td style="font-weight:bold;opacity: .6;height:25px">Hosting:</td>
                                <td></td>
                                <td>
                                    <span style="vertical-align: middle">{{ event.host }}</span>
                                    <img [src]="event.hostImage" *ngIf="event.hostImage"
                                         style="vertical-align: middle;max-height: 25px;max-width: 25px;margin-left: 10px;"/>
                                </td>
                            </tr>
                            <tr *ngIf="event.location && (!event.host || !event.location.includes(event.host))">
                                <td style="font-weight:bold;opacity: .6;height:25px" valign="top">Where:</td>
                                <td></td>
                                <td valign="top">
                                    <a *ngIf="event.location[0] === '#'"
                                       style="display:block"
                                       href="https://discord.gg/8RQe3DBRNS">Discord Server</a>
                                    {{ event.location }}
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div style="max-width: 50%;" *ngIf="event.coverImage">
                        <a [routerLink]="['/events/lightbox', encodeURI(event.coverImage)]">
                            <img [src]="event.coverImage" class="desktop-only"
                                 style="max-height: 150px;max-width: 100%;border-radius: 5px 20px"
                            />
                        </a>
                    </div>
                </div>
                <div>
                    <div [innerHTML]="marked(event.shortDescription)" class="event-description"></div>

                    <p>
                        <small *ngIf="event.parent" style="display:block"><i>Part of <b>{{ event.parent.name }}</b></i></small>
                    </p>
                </div>

            </div>
        </ng-container>
        <!--        <div class="text-center" style="margin-top: 0.5cm">-->
        <!--            <a href="#" class="btn bg-gray">View Past Events...</a>-->
        <!--        </div>-->
    </div>
</div>
<router-outlet style="position: absolute"></router-outlet>