import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { APIPlayerProfile, VMKLegacyAPI } from '@vmk-legacy/common-ts'

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private profileSource = new BehaviorSubject<APIPlayerProfile | undefined>(undefined)
    profile = this.profileSource.asObservable()

    constructor(private vmklAPI: VMKLegacyAPI) {
    }

    getProfile(ign: string): Promise<APIPlayerProfile> {
        return this.vmklAPI.getProfile(ign).then(data => {
            this.profileSource.next(data)
            return data
        })
    }
}
