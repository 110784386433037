<nav [ngClass]="{ active: showNav }">
    <div class="nav-toggle-container d-flex align-items-center">
        <a routerLink="/" class="logo-link">
            <h1>
                <img src="assets/img/VMK_Legacy_Emblem.png"
                     alt="VMK Legacy" />
            </h1>
        </a>
        <div style="flex-grow:1"></div>
        <button class="toggle-nav d-flex justify-content-center align-items-center"
                (click)="onToggleNav()"
                aria-label="Menu"
                role="button"
                aria-controls="navigation">
            <div class="nav-line-container">
                <span class="nav-line"></span>
                <span class="nav-line"></span>
                <span class="nav-line"></span>
                <span class="sr-only">Menu</span>
            </div>
        </button>
    </div>
    <div id="navigation">
        <a routerLink="/" class="logo-link desktop-only">
            <img src="assets/img/VMK_Legacy_Emblem.png"
                 alt="VMK Legacy"
            />
        </a>
        <div style="flex-grow: 1"></div>
        <a routerLink="/news" class="nav-link" routerLinkActive="active">News</a>
        <a routerLink="/events" class="nav-link" routerLinkActive="active">Events</a>
        <a *ngIf="!hasRulesArticle()"
           routerLink="/rules" class="nav-link" routerLinkActive="active">Rules</a>
        <a routerLink="/help" class="nav-link" routerLinkActive="active">Help</a>
        <a routerLink="/kb" class="nav-link" routerLinkActive="active" *ngIf="kbActive()">KB</a>
        <a *ngFor="let page of navPages" [routerLink]="'/kb/page/' + page.slug" class="nav-link"
           routerLinkActive="active">{{ page.title }}</a>
        <div style="flex-grow: 1"></div>
    </div>
</nav>
